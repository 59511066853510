
import {
  Component,
  Vue,
} from 'nuxt-property-decorator'
import {
  Enum_Page_Layout,
  PageEntity,
} from '~/graphql/generated'

import pageBySlugQuery from '~/graphql/queries/pageBySlug.graphql'


@Component
export default class Page extends Vue {

  page: PageEntity

  get shareUrl(): string {
    return encodeURI(`${this.$config.baseUrl}${this.$route.path}`)
  }

  get pageLayout(): string {
    switch (this.page.attributes.layout) {
      case Enum_Page_Layout.Default:
        return 'LayoutsDefault'
      case Enum_Page_Layout.WithoutIntro:
        return 'LayoutsWithoutIntro'
      default:
        return 'LayoutsDefault'
    }
  }

  get shareTitle(): string {
    return this.page.attributes.seo && this.page.attributes.seo.metaTitle ? this.page.attributes.seo.metaTitle : this.page.attributes.title
  }

  async asyncData({
    params,
    query,
    app: { apolloProvider },
    error,
  }): Promise<{ page: PageEntity }> {
    const pages = await apolloProvider?.defaultClient.query({
      query: pageBySlugQuery,
      variables: {
        slug: params.slug || 'home',
      },
    })
    if (!pages.data.pages.data.length) {
      error({
        statusCode: 404,
        message: 'Seite nicht gefunden',
      })
      return
    }
    const page = pages.data.pages.data[0]
    if (!page.attributes.ready && query.preview === undefined) {
      error({
        statusCode: 404,
        message: 'Seite nicht gefunden',
      })
      return
    }
    return {
      page,
    }
  }

  head(): any {
    return {
      title: this.page.attributes.seo && this.page.attributes.seo.metaTitle ? this.page.attributes.seo.metaTitle : `${this.page.attributes.slug !== 'home' ? `${this.page.attributes.title} | ` : ''}Coaching & Beratung für Burnout Prävention & Stressmanagement`,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.page.attributes.seo && this.page.attributes.seo.metaDescription ? this.page.attributes.seo.metaDescription : 'Mental Coaching, Einzelcoaching und Unternehmens-Coaching für Burnout Prävention und Stressmanagement',
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: this.page.attributes.seo && this.page.attributes.seo.metaTitle ? this.page.attributes.seo.metaTitle : this.page.attributes.title,
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: this.page.attributes.seo && this.page.attributes.seo.metaDescription ? this.page.attributes.seo.metaDescription : 'Mental Coaching, Einzelcoaching und Unternehmens-Coaching für Burnout Prävention und Stressmanagement',
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: this.page.attributes.seo && this.page.attributes.seo.metaImage.data ? this.$img(this.page.attributes.seo.metaImage.data?.attributes.formats.small.url) : `${this.$config.baseUrl}/social.jpg`,
        },
        {
          hid: 'robots',
          property: 'robots',
          content: this.page.attributes.seo && this.page.attributes.seo.metaRobots ? this.page.attributes.seo.metaRobots : 'index, follow',
        },
      ],
    }
  }
}
